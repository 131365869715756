<template>
    <div class="input-box mt--25">
        <v-text-field
            type="input"
            append-inner-icon="mdi-magnify"
            auto-select-first
            hide-details
            class="flex-full-width md-width-vw-40 "
            density="comfortable"
            item-props
            menu-icon=""
            placeholder="Search Posts"
            
            
            
            variant="plain"

    ></v-text-field>
    </div>

</template>
<script>
</script>
<style>
.input-box{
    padding: 1px;
    max-height: 40px;
    display: flex;
    flex: 1 1 auto;
    position: relative;
    
    width: 50vw;
    min-width: 200px;
    max-width: 700px;
    top: 10px;
}
.input-box .v-input{
    max-width: 500px;
    margin: auto;
    margin-top: 0px;
    background-color: transparent;
}
.mt--25{
    margin-top: -25px !important;
}
@media (max-width: 769px) {
    .md-width-vw-40{
        width: 40vw;
    }
    
}
@media (max-width: 425px) {
    .md-width-vw-40{
        width: 20vw;
    }
    
}
</style>