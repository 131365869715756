<template>
<v-row v-if="!$vuetify.display.xs" class="mt-0">
    <v-col class="pb-0">{{  item.type }}</v-col>
    <v-col class="font-size-15 pb-0">{{ item.name }}</v-col>
    <v-col class="font-size-15 pb-0">{{ item.year }}</v-col>
    <v-col cols="2" sm="4" md="2"  lg="2"  xl="2" class="font-size-15 pb-0 justify-content-right text-align-end">
        <v-icon>mdi-trash-can-outline </v-icon>
        <v-icon>mdi-pencil-outline </v-icon>
    </v-col>

</v-row>
<v-row v-else>
  <v-col class="font-weight-bold font-size-12" cols="6">Type</v-col> <v-col cols="6" class="pb-0 text-align-end">{{  item.type }}</v-col>
  <v-col class="font-weight-bold font-size-12" cols="6">Name</v-col> <v-col cols="6" class="font-size-15 pb-0 text-align-end">{{ item.name }}</v-col>
  <v-col class="font-weight-bold font-size-12" cols="6">Uploaded on</v-col> <v-col cols="6" class="font-size-15 pb-0 text-align-end">{{ item.year }}</v-col>
  <v-col cols="6"></v-col> <v-col cols="6"  class="font-size-15 pb-0 justify-content-right text-align-end">
      <v-icon>mdi-trash-can-outline </v-icon>
      <v-icon>mdi-pencil-outline </v-icon>
  </v-col>
</v-row>
</template>
<script>

// import HomeComp from './components/HomeComp.vue';
// import ForLoop from './components/ForLoop.vue';
//import Temp from './components/Temp.vue';


export default {
  name: 'ListDocumentsRow',
  props: {
    item: Object
  },
  components: {
    //Home,
    // HomeComp,
    // ForLoop
    // Demo,
  }
  ,
  mounted(){
  

  },
  data(){
    return {
      drawer: false,
      routes: ['Login','AddSchool','PasswordForgotten'],
      dashboardRoutes: ['Dashboard', 'Manage'],
      publicRoutes: ['Posts', 'About']
    }
  }
}
</script>
<style scoped>
.text-align-end{
    text-align: end;
}
</style>