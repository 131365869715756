<template>
    <div class="navbar nav-custum-bar bg-base-100  pa-0">
      <div class="flex-1 pl-5">
        <RouterLink to="/" class="text-black normal-case text-xl"
          >PTA Expense Tracking</RouterLink
        >
      </div>
     
    
    </div>
    
  </template>
<script>
export default {
  components: {
  },
  data(){
    return {
      open: false
    }
  }
}
</script>
<style scoped>
.navbar{
  min-height: 56px;
}
.nav-custum-bar{
  background-color: #D9D9D9;
  color: black;
}

.nav-custum-bar .menu{
  z-index: 300;
}
.pt-18{
  padding-top: 18px;
}
.nav-link-btn{
  background-color: #355698;
  color: white;
  border-radius: 5px;
  min-width: 90px;
  text-align: center;
  display: inline-block;
  
}
</style>