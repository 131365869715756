import DashBoard from "../views/Dashboard.vue";
import Manage from "../views/Manage.vue";
import Login from "../views/Login.vue";
import PasswordForgot from "../views/PasswordForgot.vue";
const routes = [
    {
      path: '/',
      name: 'Posts',
      components: {
        default: () => import('../views/PostIndex.vue'),
        LeftSidebar: () => import('../widgets/Sidebards/SidebarOne.vue'),
        RightSidebar: () => import('../widgets/Sidebards/Schools.vue'),
      }
    },
    {
      path: '/about',
      name: 'About',
      components: {
        default: () => import('../views/AboutShow.vue'),
        LeftSidebar: () => import('../widgets/Sidebards/SidebarOne.vue'),
        RightSidebar: () => import('../widgets/Sidebards/SidebarTwo.vue'),
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: DashBoard,
    },
    {
      path: '/manage',
      name: 'Manage',
      component: Manage,
    },
    {
      path: '/password-forgotten',
      name: 'PasswordForgotten',
      component: PasswordForgot,
    },
    {
      path: '/add-school',
      name: 'AddSchool',
      components: {
        default: () => import('../views/AddSchool.vue'),
      },
    },
]
export default routes;