import { createVuetify } from 'vuetify'
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';

import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives'
import { useI18n } from 'vue-i18n';
import i18n from './i18n';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';


//import Vue from "vue"

const theme = {
  primary: '#4CAF50',
  secondary: '#9C27b0',
  accent: '#9C27b0',
  info: '#00CAE3',
}

const Vuetify = createVuetify({
  components,
  directives,
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  theme: {
    dark: false,
    themes: {
      dark: theme,
      light: theme,
    },
  },
})




export default Vuetify;
