<template>
    <v-container fluid class="manage pt-0">
            <v-dialog class="upload" v-model="dialog" max-width="500px">

                <v-card>


                    <v-card-text>
                        <v-container fluid>

                        <v-alert v-if="dialogAlert" :type="dialogAlertType" :title="$t(dialogAlertTitle)" :text="$t(alertSubTitle)"></v-alert>
                        <v-form @submit.prevent v-if="this.editedIndex === -1">
                            <slot  :update="update" :data="createdItem"   name="add-dialogue"></slot>
                            <v-row>
                                <v-col>
                                    <p class="custum-label">Type</p>
                                    <v-select
                                        class="custum-input" density="compact" variant="tonal"
                                        hide-details
                                        label=""
                                        :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']"
                                        ></v-select>
                                </v-col>
                                <v-col cols="auto">
                                    <div class="d-flex h-percent-100 align-items-end"><v-btn size="40" variant="flat" icon="mdi-paperclip"></v-btn></div>
                                </v-col>

                            
                                </v-row>

                        
                            
                        </v-form>
                        <v-form @submit.prevent v-else>
                            <slot  :update="update" :data="editedItem"  name="edit-dialogue"></slot>
                        </v-form>
                        
                        

                        </v-container>
                    </v-card-text>

                    <v-card-text>
                    
                        
                        <v-container fluid>
                            <v-row>
                                <v-col>
                                    <v-btn color="blue-darken-1" class="px-36 w-100 ma-auto  pt-2 pb-2 min-width-150 text-transforme-capitalize" variant="flat" @click="save">
                                {{ $t('send_btn') }}
                                </v-btn>
                                </v-col>

                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogT" max-width="500px">

                <v-card>


                    <v-card-text>
                        <v-container>

                        <v-alert v-if="dialogAlert" :type="dialogAlertType" :title="$t(dialogAlertTitle)" :text="$t(alertSubTitle)"></v-alert>
                        <v-form @submit.prevent v-if="this.editedIndex === -1">
                            <slot  :update="update" :data="createdItem"   name="add-dialogue"></slot>
                            <v-select
                            label="Select"
                            :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']"
                            ></v-select>
                            
                            
                        </v-form>
                        <v-form @submit.prevent v-else>
                            <slot  :update="update" :data="editedItem"  name="edit-dialogue"></slot>
                        </v-form>
                        
                        

                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue-darken-1" class="px-36 pt-2 pb-2 min-width-150 text-transforme-capitalize" variant="outlined" @click="close">
                        {{ $t('cancel_btn') }}
                        </v-btn>
                        <v-btn color="blue-darken-1" class="px-36 pt-2 pb-2 min-width-150 text-transforme-capitalize" variant="flat" @click="save">
                        {{ $t('save_btn') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-row class="mx-percent-10 mt-5 pt-0 bg-grey-3">
                <v-col class="pt-0" cols="12"><h1 class=" font-size-20 font-weight-bolder">Lycée d’Etoug-Ebe</h1></v-col>
                <v-col cols="10" xs="12" sm="8" md="10"  lg="10" xl="10" class="font-weight-bold">Budget:</v-col>
                <v-col class="justify-content-start text-align-end  "    cols="12" sm="4" md="2" lg="2" xl="2" >
                    <v-btn  @click="dialog = true"  color="primary"  variant="flat" class="mb-5 min-width-150 pt-2 pb-2 bg-primary text-white vertical-align-bottom text-transforme-capitalize  px-36"   >
                        Add
                    </v-btn></v-col>
                <v-col cols="12">
                    Current
                </v-col>
                <v-col cols="12">
                    <v-row v-if="!$vuetify.display.xs">
                        <v-col class="font-weight-bold font-size-12">Year</v-col>
                        <v-col class="font-weight-bold font-size-12">Unit</v-col>
                        <v-col class="font-weight-bold font-size-12">Students</v-col>
                        <v-col cols="2" sm="4" md="2"  lg="2"  xl="2" class="font-weight-bold font-size-12 justify-content-start text-align-end">Total</v-col>
                    </v-row>
                    <ListBudgetsRow :item="budgets[0]"></ListBudgetsRow>
                    
                    <v-row>
                        <div class="bg-black divider"></div>
                    </v-row>
                </v-col>
                <v-col cols="12" class="font-size-16 font-weight-bold">
                    Previous years
                </v-col>
                <v-col cols="12">
                    <template v-for="budget,index of budgets" :key="index">
                        <ListBudgetsRow :item="budget"></ListBudgetsRow>
                        <v-row class="mt-0">
                            <div class="bg-black divider"></div>
                        </v-row>  
                    </template>
                    
                       
                </v-col>
                <v-col cols="12">
                    <p>Peoples</p>
                    <v-text-field density="compact" class="w-percent-50" variant="outlined"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <template v-if="!$vuetify.display.xs">
                        <v-row>
                            <v-col></v-col>
                            <v-col class="font-weight-bold font-size-12">Name</v-col>
                            <v-col class="font-weight-bold font-size-12">Position</v-col>
                            <v-col class="font-weight-bold font-size-12">Date joined</v-col>
                            <v-col cols="2" sm="4" md="2"  lg="2"  xl="2" class="font-weight-bold font-size-12 justify-content-end  text-align-end ">Last update</v-col>
                        </v-row>
                        
                        <v-row class="mt-0">
                            <div class="bg-black divider"></div>
                        </v-row>
                    </template>
                    <template v-for="user,index of users" :key="index" >
                        <ListUsersRow :item="user" />
                        <v-row class="mt-0">
                            <div class="bg-black divider"></div>
                        </v-row>
                    </template>
                </v-col>
                <v-col  cols="10" sm="8" md="10" lg="10" xl="10" class="font-weight-bold">Verification documents:</v-col>
                <v-col  cols="12" sm="4" md="2"  lg="2"  xl="2"  class="  justify-content-end text-align-end ">
                    <v-btn  @click="openDialog()"  color="primary"  variant="flat" class="mb-5 bg-primary min-width-150 pt-2 pb-2 px-36 text-white vertical-align-bottom text-transforme-capitalize mr-0"   >
                        Upload
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <v-row v-if="!$vuetify.display.xs">
                    
                        <v-col class="font-weight-bold font-size-12">Name</v-col>
                        <v-col class="font-weight-bold font-size-12">Type</v-col>
                        <v-col class="font-weight-bold font-size-12">Updated on</v-col>
                        <v-col cols="2" class="font-weight-bold font-size-12"></v-col>
                    </v-row>
                    <template v-for="document, index of docs" :key="index">
                        <ListDocumentsRow :item="document"></ListDocumentsRow>
                    </template>
                </v-col>
            </v-row>
    </v-container>
    
</template>
<script>
import Camera from '../assets/Camera.svg';
import Logo from '../assets/logo.png';
import ListBudgetsRow from '../widgets/Display/ListBudgetsRow.vue';
import ListUsersRow from '../widgets/Display/ListUsersRow.vue';
import ListDocumentsRow from '@/widgets/Display/ListDocumentsRow.vue';

export default {
components: {
    ListBudgetsRow,
    ListUsersRow,
    ListDocumentsRow
},
methods: {
    choose(item){
        this.currentMenu = item;
    },
    manage(){
        this.$router.push('/manage')
    },
    openDialog(){
        this.dialog = true;
    },
    close(){
        this.dialog = false;
    }
},
data(){
  return {
    editedIndex: -1,
    open: false,
    camera: Camera,
    logo: Logo,
    currentMenu: 1,
    customHeaders: [
    {
        title: "Name",
        align: 'start',
        sortable: true,
        width: '40%',
        key: 'name',
    },{
        title: "Post",
        align: 'start',
        sortable: true,
        key: 'posts',
    }
    ,{
        title: "Deadline",
        align: 'start',
        sortable: true,
        key: 'dateline',
    }
    ,{
        title: "",
        align: 'start',
        sortable: true,
        key: 'actions',
    }
    ],
    stats: [
        {
            title: 'salut',
            icon: ''
        },
        {
            title: 'salut',
            icon: ''
        }
    ],
    dialog: false,
    dialogT: false,
    selected: {text: 'Real-Time', icon: 'mdi-progress-helper'},
    posts: [
          { title: "Post 1", id: "1", date: "23 oct 2020", username: "paul" },
          { title: "Post 1", id: "1", date: "23 oct 2020", username: "paul" },
          { title: "Post 1", id: "1", date: "23 oct 2020", username: "paul" },
          { title: "Post 1", id: "1", date: "23 oct 2020", username: "paul" }
    ],
    budgets: [
        {
            yearsRange: "2022-2023",
            students: 10,
            amountUnit: 34000,
            amountTotal: 340000
        },
        {
            yearsRange: "2021-2022",
            students: 10,
            amountUnit: 34000,
            amountTotal: 340000
        },
        {
            yearsRange: "2020-2021",
            students: 10,
            amountUnit: 34000,
            amountTotal: 340000
        }
    ],
    projects: [
        { name: "Post 1", id: "1", dateline: "23 oct 2020", posts: "14", budget: 20000 },
        { name: "Post 1", id: "2", dateline: "23 oct 2020", posts: "14", budget: 20000 },    
    ],
    docs: [
        {
            type: "class",
            name: "Patente",
            year: "2023"
        }
    ],
    users: [
        {
            avatar: "https://cdn.vuetifyjs.com/images/john.jpg",
            name: "Eugene Clotaire",
            role: "Admin",
            joinedAt: "11-05-2000",
            lastUpdate: "11-05-2000"
        }
    ],
    items: [
        { text: 'Real-Time', icon: 'mdi-progress-helper' },
        { text: 'Audience', icon: 'mdi-progress-helper' },
        { text: 'Conversions', icon: 'mdi-progress-helper' },
      ],
    menuItems: [
      { text: 'Account settings', icon: 'mdi-account-cog-outline' },
      { text: 'Logout', icon: 'mdi-logout'}
    ]
  }
}
}
</script>
<style scoped>
.font-weight-bolder{
    font-weight: bolder;
}
.bg-img{
    background-color: #EAEAEA;  
}
.h-percent-100{
    height: 100%;
} 
.align-items-end{
    align-items: end;
}
.h-px-40{
    height: 40px;
}
.pt-px-5{
    padding-top: 5px;
}
.gap-15{
    gap: 15px;
}
.font-size-15{
    font-size: 15px;
}
.font-size-12{
    font-size: 12px;
}
.font-size-11{
    font-size: 11px;
}
.w-percent-50{
    width: 50%;
}
.chevrondown-content{
  background-color: #EAEAEA;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  border: solid 2px white;
  padding: 5px;
  top: 75%;
  left: 75%;

}
.action{
    display: none;
}
.price{
    position: relative;
}
.text-align-end{
    text-align: end;
}
.bg-black{
    background-color: black;
}
.font-size-20{
    font-size: 20px !important;
}
.font-size-16{
    font-size: 16px;
}
.price:hover .action{
    display: inline-block;
    position: absolute;
    top: 0%;
    left: 0%;
    background-color: rgba(0, 0, 0, 0.075);
}
.expense-title{
    font-size: 15px;
}
.media{
    border-radius: 5px;
    width: 50%;
}
.fontWeigthBolder{
    font-weight: bolder;
}
.chevrondown{
  
width: 100%;
height: 100%;
}
.color-primary{
    color: #158CCE;
}
.border-gray{
 border-color: #DDDDDD;
}
.medias-box{
 min-height: 30vh;
 border: solid 1px #DDDDDD;
 border-radius: 5px;
 max-height: 60vh;
}
.px-percent-10{
    padding-left: 10%;
    padding-right: 10%;
}

.mx-percent-10{
    padding-left: 10%;
    padding-right: 10%;
}
.px-36{
    padding-left: 36px !important;
    padding-right: 36px !important;
}
.gap-2{
    gap: 2px;
}
.w-px-200{
    width: 150px;
    height: 150px;
}
.text-transform-capitalize{
    text-transform: capitalize;
}
.direction-rtl{
    direction: rtl;
}
.font-size-10{
    font-size: 10px;
}
.stat-text{
    font-weight: bolder;
    font-size: 18px;
}
.justify-content-center{
    justify-content: center;
}
.divider{
    height: 2px;
    background-color: #DDDDDD;
    width: 100%;
}
.hide{
    display: none;
}
.text-align-left{
    text-align: left;
}
.divider{
    display: flex;
    height: 1px;
    widows: 100%;
}
.justify-content-right{
    justify-content: right;
}
.bg-grey-3{
    background-color: #F8F8F8;
}
@media screen and (max-width: 475px){
    .manage{
        padding: 0px;
    }
}
@media  (max-width: 599px){
.mx-percent-10{
    padding-left: 5px;
    padding-right: 5px;
}
}
</style>