<script setup>
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
import TheNavbar from './widgets/NavBars/TheNavbar.vue';
import TheNavbarToHome from './widgets/NavBars/TheNavbarToHome.vue';
import TheDashboardNavbar from './widgets/NavBars/TheDashboardNavbar.vue';
import TheFooter from './widgets/NavBars/TheFooter.vue';
</script>

<template>
  <v-layout>
    <v-navigation-drawer   temporary v-model="drawer">
      <v-list-item title="PTA Expense Tracking" subtitle=""></v-list-item>
      <v-divider class="mt-15"></v-divider>
      <v-list-item link prepend-icon="mdi-home" title="Home"></v-list-item>
      <v-list-item link prepend-icon="mdi-account" title="Profil"></v-list-item>
      <v-list-item link to="/add-school" prepend-icon="mdi-plus" title="Add school"></v-list-item>
      <v-list-item link to="/login" prepend-icon="mdi-login" title="Login"></v-list-item>
      <v-list-item link to="/logout" prepend-icon="mdi-logout" title="Log out"></v-list-item>
    </v-navigation-drawer>
     <v-main class="d-flex flex-direction-column">
      <TheNavbarToHome v-if="routes.includes($route.name)  "></TheNavbarToHome>
      <TheNavbar v-if="publicRoutes.includes($route.name)  "></TheNavbar>
      <TheDashboardNavbar v-if="dashboardRoutes.includes($route.name)  "></TheDashboardNavbar>
      <div id="router" class="sm:flex pb-20 justify-content-center container w-max-100 app-background  mx-auto content justify-betweenflex-wrap ">
        <RouterView class="view main-content w-full max-width-1440 order-2"></RouterView>
        <RouterView name="LeftSidebar" class="hide-mobile view order-1 w-full"></RouterView>
        <RouterView name="RightSidebar" class="hide-mobile view order-3 w-full"></RouterView>
      </div>
      <TheFooter />
     </v-main>

  </v-layout>
</template>
<script>

// import HomeComp from './components/HomeComp.vue';
// import ForLoop from './components/ForLoop.vue';
//import Temp from './components/Temp.vue';


export default {
  name: 'App',
  components: {
    //Home,
    // HomeComp,
    // ForLoop
    // Demo,
  }
  ,
  mounted(){
    window.addEventListener('showdrawer',()=>{
      this.drawer = true;
    })

  },
  data(){
    return {
      drawer: false,
      routes: ['Login','AddSchool','PasswordForgotten'],
      dashboardRoutes: ['Dashboard', 'Manage'],
      publicRoutes: ['Posts', 'About']
    }
  }
}
</script>
<style>
  .bg-blue{
    background-color: white;
    color: black;
  }
  .w-max-100{
    max-width: 100%;
  }
  .flex-direction-column{
    flex-direction: column;
  }
  @media (max-width: 597px){
    .hide-mobile{
      display: none;
    }
  }
  @media (min-width: 597px ) and (max-width: 768px){
    .hide-tablet{
      display: none;
    }
  }
  .app-background{
    background-color: white;
    color: black;
  }
  .justify-content-center{
    justify-content: center;
  }
  
  .max-width-1440{
    max-width: 1440px !important;
  }
</style>