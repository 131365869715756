<template>
    <div class=" sized max-width-500   w-100 ">
        <v-card variant="flat" class="text-color-white  pr-0">
           
            <v-toolbar v-if="!post" color="transparent" class="px-0 pr-=">
        
              

                <v-spacer></v-spacer>


                <v-btn v-if="!post" color="primary"  variant="flat" class="mb-5 min-width-150 px-36 pt-2 pb-2 bg-primary text-white vertical-align-bottom text-transforme-capitalize mr-0"  @click="post = true" >
                    Post
                </v-btn>


            </v-toolbar>

            <v-expand-transition>

                <v-window v-if="post"  v-model="tabs">
                    <v-window-item
                        
                        :key="1"
                        :value="1"
                    >
                        <v-container fluid>
                            <v-row>
                                <v-col class="pr-0 pt-0 pb-0 pl-0" cols="auto">
                                    <v-avatar size="40">
                                        <v-img
                                            src="https://cdn.vuetifyjs.com/images/john.jpg"
                                            alt="John"
                                        ></v-img>
                                    </v-avatar>
                                </v-col>
                                <v-col class="direction-rtl pt-0 pl-0 pb-0 pr-0">
                                    <v-spacer></v-spacer>
                                
                                    <v-icon
                                        @click="clear()"
                                        color="red"
                                        icon="mdi-close"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="auto" class="pr-0 pl-0">
                                    <v-avatar class="visibilty-none" size="40">
                                        <v-img
                                            src="https://cdn.vuetifyjs.com/images/john.jpg"
                                            alt="John"
                                        ></v-img>
                                    </v-avatar>
                                </v-col>
                                <v-col class="card-pl-pr-px-16-5 ">
                                    <v-card variant="flat" class=" text-color-white bg-app-blue pt-0 pr-0">
                                        
                                        <v-form
                                        ref="form"
                                        v-model="isValid"
                                        class="  color-black"
                                        >
                                            <p class="custom-label">Description</p>
                                            <v-textarea
                                            
                                            variant="tonal"
                                            auto-grow
                                            rows="2"
                                            shapped
                                            density="compact"
                                            v-model="description"
                                            row-height="15"
                                            style="width: 100%;"
                                            ></v-textarea>   
                                            <p class="custum-label">Project</p>
                                            <v-select density="compact" variant="tonal">
                                                <option>Project 1</option>
                                            </v-select> 
                                            <p class="custum-label">Amount Spent</p>
                                            <v-text-field
                                                v-model="spent"

                                                variant="tonal"
                                            
                                                density="compact"
                                                
                                            
                                                type="number"
                                            ></v-text-field>
                                            <input @change="addImage" hidden multiple accept="image/*" type="file" id="postImage"/>
                                            <v-container :class="filesContainer" fluid>
                                                <v-row class="gap-1">
                                                    <v-col class=" position-relative pa-0 pr-0 " v-for=" file,key of files " :key="key">
                                                        <v-icon @click="remove(key)" style="position: absolute;" class="close">
                                                            mdi-close-circle
                                                        </v-icon>
                                                        <img  class="img-preview border-rounded d-flex card-image" :src="getURL(file)"/>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                            
                                            
                                        </v-form> 
                                        <div class="d-flex mt-5">
                                            <v-icon @click="postImage"  class="mb-3 mt-px-6 vertical-align-middle">mdi-image</v-icon> 
                                            <v-icon class="mb-3 mt-px-6 ml-3 vertical-align-middle">mdi-attachment</v-icon>
                                            <v-spacer class="vertical-align-middle"></v-spacer>
                                            <v-btn color="primary" variant="outlined"  class="mb-5 px-36 rounded-5 vertical-align-bottom text-transforme-capitalize">Preview</v-btn>
                                            
                                            <v-btn color="primary" variant="flat"  class="mb-5 px-36 ml-5 rounded-5 vertical-align-bottom text-transforme-capitalize">Submit</v-btn>
                                        </div>

                                    </v-card>
                                </v-col>
                        
                            </v-row>
                        </v-container>
                        
                    </v-window-item>
               
                </v-window>
            </v-expand-transition>
        </v-card>
        <v-divider></v-divider>
    </div>        
</template>
<script>
  export default {
    methods: {
        clear(){
            this.post = false;
            this.description = "";
            this.spent = 0;
        },
        postImage(){
            document.getElementById("postImage").click();

        },
        addImage(e){
            this.files = [...this.files,...e.target.files]
            
        },
        remove(index){
            this.files = this.files.filter((value,p)=>index!=p)
        },
        getURL(file){
            return URL.createObjectURL(file);
        }
    },
    data () {
      return {
        post: false,
        files: [],
        tabs: null,
        description: "",
        model: 'tab-2',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        password: undefined, 
        isValid: false,
        rules: {
            email: v => !!(v || '').match(/@/) || 'Please enter a valid email',
            length: len => v => (v || '').length >= len || `Invalid character length, required ${len}`,
            password: v => !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) || 'Password must contain an upper case letter, a numeric character, and a special character',
            required: v => !!v || 'This field is required',
        }
      }
    },
  }
</script>
<style >
.close{
    position: absolute;

    right: 5px;
    top: 5px;
}
.text-color-white{
    color: white;
}
.rounded-5{
    border-radius: 5px;   
}
.card-pl-pr-px-16-5{
    padding-right: 5px !important;
    padding-left: 16px !important;
}
.post-btn{
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    padding-bottom: 5px;
}
.position-relative{
    position: relative;
    left: 0;
    top: 0;
}
.flex-all{
    display: flex; 
    flex: 1;
}
.gap-1{
    gap: 1px;
}
.vertical-align-bottom{
    vertical-align: bottom;
    align-self: end;

}
.v-tab__slider{
    background-color: blue;
}
.text-transforme-capitalize{
    text-transform: capitalize;
}
.text-white{
    color: white !important;
}
.card-padding{
    padding: 5px;
}
.mt-px-6{
    margin-top: 6px;
}
.visibilty-none{
    pointer-events: none;
    opacity: 0;
}
.border-rounded{
    border-radius: 15px;
}
.img-preview{
    min-width: 150px;
}
.color-black{
    color: black;
}
.close{
    position: absolute;
    top: 10px;
    right: 10px;
}
.direction-rtl{
    direction: rtl;
}
</style>