
//import upperFirst from 'lodash/upperFirst'
//import camelCase from 'lodash/camelCase'
import CustomSearch from '@/components/base/CustomSearch.vue';
//import DataList from '@/components/base/DataList';
//import FieldInput from '@/components/base/FieldInput';
//import FieldView from '@/components/base/FieldView';
//import OffLineNotification from '@/components/base/OffLineNotification';
//import Notification from '@/components/base/Notification';



function register(app){
  app.component("CustomSearch", CustomSearch.default || CustomSearch);
}
export default register;
