import { createI18n } from 'vue-i18n'

import en from 'vuetify/lib/locale/en'
import fr from 'vuetify/lib/locale/fr'
import localFr from "@/locales/fr.json"
import localEn from "@/locales/en.json"
import { isEmpty } from 'lodash'


const datetimeFormats = {
  'fr': {
    short: {
      year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false
    },
    medium: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: false
    },
    long: {
      year: 'numeric', month: 'long', day: 'numeric',
      weekday: 'long', hour: 'numeric', minute: 'numeric', hour12: true
    }
  },
  'en': {
    short: {
      year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false
    },
    medium: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: false
    },
    long: {
      year: 'numeric', month: 'long', day: 'numeric',
      weekday: 'long', hour: 'numeric', minute: 'numeric', hour12: true
    }
  }
}
const messages = {
  en: {
    ...localEn,
    $vuetify: en,
  },
  fr: {
    ...localFr,
    $vuetify: fr,
  }
}

let locale = localStorage.getItem('locale');
if(isEmpty(locale)){
  locale = process.env.VITE_I18N_LOCALE || 'en'
}
const i18n = createI18n({
  legacy: false,
  locale,
  fallbackLocale: process.env.VITE_I18N_FALLBACK_LOCALE || 'en',
  messages,
  datetimeFormats
})

export default i18n;