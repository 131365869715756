/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'
import { FRONT } from '@/constants/routes';
import { isAuthentificate } from '@/utils/helpers';
import routes from "../router";



const router = createRouter({

  history: createWebHistory('/'),
  routes,
});

router.beforeEach((to, from) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  const userIsAuthentificate = isAuthentificate() 
  if (to.meta.requiresAuth && !userIsAuthentificate) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return {
      path: FRONT.AUTHENTIFICATION.SINGN_IN,
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    }
  }

  if(userIsAuthentificate && (to.fullPath == FRONT.AUTHENTIFICATION.SINGN_IN || to.fullPath == FRONT.AUTHENTIFICATION.PASSWORD_FORGOT) ){
    return {
      path: FRONT.DASHBOARD.BASE,
      
    }
  }
})
export default router;