<template>
<v-row v-if="!$vuetify.display.xs" class="mt-0">
  <v-col class="pb-0 pt-px-5">
    <v-avatar>
      <v-img
      :src="item.avatar"
      alt="John"
      ></v-img>
    </v-avatar>
  </v-col>
  <v-col class="font-size-15 pb-0">{{ item.name }}</v-col>
  <v-col class="font-size-15 pb-0">{{ item.role }}</v-col>
  <v-col class="font-size-15 pb-0">{{ item.joinedAt }}</v-col>
  <v-col cols="2" sm="4" md="2"  lg="2"  xl="2" class="font-size-15 pb-0 justify-content-end text-align-end">{{ item.lastUpdate }}</v-col>
</v-row>
<v-row v-else>
  <v-col class="font-weight-bold font-size-12" cols="6" >
    Name
  </v-col>
  <v-col  cols="6" class="font-size-15 pb-0 text-align-end">
    <v-avatar>
      <v-img
      :src="item.avatar"
      alt="John"
      ></v-img>
    </v-avatar> {{ item.name }}
  </v-col>
  <v-col class="font-weight-bold font-size-12" cols="6">
    Position
  </v-col> 
  <v-col cols="6" class="font-size-15 pb-0 text-align-end">{{ item.role }}</v-col>
  <v-col class="font-weight-bold font-size-12" cols="6">
    Date joined
  </v-col> 
  <v-col class="font-size-15 pb-0 text-align-end">{{ item.joinedAt }}</v-col>
  <v-col class="font-weight-bold font-size-12" cols="6">
    Last update
  </v-col> 
  <v-col cols="6"  class="font-size-15 pb-0 justify-content-end text-align-end">{{ item.lastUpdate }}</v-col>
</v-row>
</template>
<script>

// import HomeComp from './components/HomeComp.vue';
// import ForLoop from './components/ForLoop.vue';
//import Temp from './components/Temp.vue';


export default {
  name: 'ListBudgetsRow',
  props: {
    item: Object
  },
  components: {
    //Home,
    // HomeComp,
    // ForLoop
    // Demo,
  }
  ,
  mounted(){
  

  },
  data(){
    return {
      drawer: false,
      routes: ['Login','AddSchool','PasswordForgotten'],
      dashboardRoutes: ['Dashboard', 'Manage'],
      publicRoutes: ['Posts', 'About']
    }
  }
}
</script>
<style scoped>
.text-align-end{
    text-align: end;
}
.font-size-15{
    font-size: 15px;
}
.pt-px-5{
    padding-top: 5px;
}
</style>