import * as Vue from "vue";

import './assets/styles.css';
import 'viewerjs/dist/viewer.css';
import App from './App.vue';
 
// Plugins
import { registerPlugins } from '@/plugins'



  
const app = Vue.createApp(App);
app.config.productionTip = false;
registerPlugins(app)
app.mount('#app')
