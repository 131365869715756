<template>
<v-row v-if="!$vuetify.display.xs">
    <v-col xs="12" class="font-size-15">{{ item.yearsRange }}</v-col>
    <v-col xs="12" class="font-size-15">{{ item.students }}</v-col>
    <v-col xs="12" class="font-size-15">{{ item.amountUnit }}</v-col>
    <v-col cols="2" xs="12" sm="4" md="2"  lg="2"  xl="2" class="font-size-15 justify-content-start text-align-end">XAF <span class="font-weight-bold">{{ item.amountTotal }}</span></v-col>
</v-row>
<v-row v-else>
  <v-col class="font-weight-bold font-size-12 " cols="6">Year</v-col> <v-col cols="6" class="font-size-15 text-align-end">{{ item.yearsRange }}</v-col>
  <v-col class="font-weight-bold font-size-12 " cols="6">Unit</v-col> <v-col cols="6" class="font-size-15 text-align-end">{{ item.students }}</v-col>
  <v-col class="font-weight-bold font-size-12" cols="6">Students</v-col> <v-col cols="6" class="font-size-15 text-align-end">{{ item.amountUnit }}</v-col>
  <v-col class="font-weight-bold font-size-12 " cols="6">Total</v-col> <v-col cols="6"  class="font-size-15 justify-content-start text-align-end">XAF <span class="font-weight-bold">{{ item.amountTotal }}</span></v-col>
</v-row>
</template>
<script>

// import HomeComp from './components/HomeComp.vue';
// import ForLoop from './components/ForLoop.vue';
//import Temp from './components/Temp.vue';


export default {
  name: 'ListBudgetsRow',
  props: {
    item: Object
  },
  components: {
    //Home,
    // HomeComp,
    // ForLoop
    // Demo,
  }
  ,
  mounted(){
   console.log(this.$vuetify)

  },
  data(){
    return {
      drawer: false,
      routes: ['Login','AddSchool','PasswordForgotten'],
      dashboardRoutes: ['Dashboard', 'Manage'],
      publicRoutes: ['Posts', 'About']
    }
  }
}
</script>
<style scoped>
.text-align-end{
    text-align: end;
}
</style>