<template>
    <footer class="p-4 footer text-center bg-neutral text-neutral-content">
      <p class="text-center w-100 d-block">© Copyright Ikwen - {{ new Date(Date.now()).getFullYear() }}</p>
    </footer>
</template>
<style>
 .text-center
 {
  text-align: center;
 }
 .w-100{
  width: 100%;
 }
</style>