<template>
    <v-container id="adminScrollArea" class="admin  pt-0" fluid>
        <v-row v-if="mobile" id="mobileNavbar" class=" mt-px--16 px-percent-10 px-xs-percent-5     ">
            <p class="mb-0 height-56 navbar-second-content"><span @click="choose(3)" :class="{ fontWeigthBolder: currentMenu == 3, ' cursor-pointer': true }"> Summary</span><span @click="choose(1)" :class="{ fontWeigthBolder: currentMenu == 1, 'cursor-pointer ml-2': true }">Expense posts</span> <span @click="choose(2)" :class="{ fontWeigthBolder: currentMenu == 2, 'ml-2 cursor-pointer': true }"> Projects</span>  <span @click="choose(4)" :class="{ fontWeigthBolder: currentMenu == 4, 'ml-2 cursor-pointer': true }"> Medias</span></p>
            <div class="divider "></div>
        </v-row>
        <v-row v-if="!mobile || (mobile && currentMenu == 1)" class="px-percent-10 px-xs-percent-5 mb-5 mt-5">
            <v-col cols="12" sm="auto" md="auto" lg="auto" xl="auto" class="pr-0 pt-0 pb-0 pl-0">
                <label tabindex="0" class="bg-img  w-px-200 btn btn-ghost btn-circle avatar ">
                    <div class="w-200 rounded-full">
                        <img class="bg-img" :src="logo" />
                        
                    </div>
                    <div class="chevrondown-content rounded-full">
                        <img class="chevrondown" :src="camera" />
                    </div>
                </label>
            </v-col>
            <v-col    cols="12" sm="5" lg="3" xl="3" md="4" class=" pl-mobile-0 pl-5 pt-0 pb-0  ">
                <h1 class="mt-10 mt-sm-2 font-size-20 font-weight-bolder xs-text-align-center">Lycée d’Etoug-Ebe</h1>
                <v-row>
                    <v-col class="pl-0 pr-0 xs-text-align-end"> <p class=" pl-px-9"> <v-icon>mdi-briefcase-variant</v-icon> 12000 XAF</p></v-col>
                    <v-col class="pl-0 pr-0"> <p> <v-icon size="15" color="#158CCE">mdi-decagram</v-icon> <span class="font-size-10">Verified</span></p>  </v-col>
                </v-row>
            </v-col>

            <v-col  class="direction-rtl pt-0 pb-0 pr-0 xs-text-align-center">
                <v-btn @click="manage()" class="text-transform-capitalize font-weight-bolder px-36 pt-2 pb-2 min-width-150 mt-sm-2 mt-15" variant="tonal"> {{ " " }} Manage <v-icon>mdi-cog</v-icon></v-btn>
            </v-col>
        </v-row>
        <v-row v-if="!mobile || (mobile && currentMenu == 3)" class="px-percent-10 mb-xs-px-5 px-xs-percent-5 mt-5">
            <v-card width="100%" class="border-gray stats-box"  variant="outlined"  subtitle="Parents following: 430">
                <template  v-slot:title >
                    <h2 >Overview</h2>
                </template>
                <v-container fluid>
                    <v-row class="gap-2  mb-5 pl-px-2  pr-px-2">
                        <v-col      class=" pa-0 stat-column" >
                            <v-card
                                class=" pa-0"
                                max-width="300"
                                min-height="120"
                            
                                variant="tonal"
                                min-width="280"
                                prepend-icon="mdi-account"
                            
                            >
                                <template v-slot:title>
                                    <h4 class="expense-title">Total expenses</h4>
                                </template>
                                <v-card-text class="pl-px-10">XAF <span class="stat-text">37,875,990</span></v-card-text>
                            </v-card>
                        </v-col>
                        <v-col    class="pa-0 stat-column">
                            <v-card
                                class="mx-auto"
                                max-width="300"
                                
                                variant="tonal"
                                min-height="120"
                                min-width="280"
                                prepend-icon="mdi-account"
                            
                            >
                                <template v-slot:title>
                                    <h4 class="expense-title">Projects</h4>
                                </template>
                                <v-card-text class="pl-px-10"><span class="stat-text">18</span></v-card-text>
                            </v-card>
                        </v-col>
                        <v-col class="pa-0 stat-column" >
                            <v-card
                                class="mx-auto"
                                max-width="300"
                                variant="tonal"
                            
                                min-height="120"
                                min-width="280"
                                prepend-icon="mdi-account"
                            
                            >
                                <template v-slot:title>
                                    <h4 class="expense-title">Expenses posts</h4>
                                </template>
                                <v-card-text class="pl-px-10"><p class="stat-text">136</p></v-card-text>
                            </v-card>
                        </v-col>
                        <v-col   class="pa-0 stat-column" >
                            <v-card
                                class="mx-auto"
                                max-width="344"
                            
                                variant="tonal"
                                prepend-icon="mdi-account"
                                min-height="120"
                                min-width="280"
                            >
                                <template v-slot:title>
                                    <h4 class="expense-title">Reactions</h4>
                                </template>
                                <v-card-text class="pl-px-10">
                                    <v-row>
                                        <v-col class="xs-pl-10 pr-0"><p>Likes</p><p class="stat-text">1k+</p></v-col>
                                        <v-col class="pr-0"><p>Dislikes</p><p class="stat-text">1k+</p></v-col>
                                        <v-col class="pr-0"><p>Comments</p><p class="stat-text">557</p></v-col>
                                    </v-row>
                                </v-card-text>    
                            
                            </v-card>
                        </v-col>

                    </v-row>
                </v-container>
            </v-card>
        </v-row>
        <v-row v-if="!mobile" class="px-percent-10 px-xs-percent-5 height-30 mt-5 mb-5 pt-3  ">
            <p class="mb-0 pb-1"><span @click="choose(1)" :class="{ fontWeigthBolder: currentMenu == 1, 'cursor-pointer': true }">Expense posts</span> <span @click="choose(2)" :class="{ fontWeigthBolder: currentMenu == 2, 'ml-5 cursor-pointer': true }"> Projects</span></p>
            <div class="divider "></div>
        </v-row>
        
 
        
        <v-row :class="{ hide: (currentMenu != 1 && ! mobile) || (mobile &&  currentMenu != 1 && currentMenu != 4)  , 'px-percent-10 xs-flex-direction-column px-xs-percent-5 ': true,  'mt-2': true }" >
            <v-col v-if="!mobile || (mobile && currentMenu == 4)" class="medias-box padding-px-5 ">
                <h5 class="font-weight-bolder pa-px-1">Medias</h5>
                <v-container class="pa-3" fluid>
                    <v-row class=" max-height-percent-80  overlow-scroll ">
                
                        <v-col v-for="img, index of imgs" :key="index" class=" d-flex child-flex pa-px-1"  cols="4" sm="6" md="4" lg="3" xl="2">
                            <v-img class="media" @click="this.$viewerApi({
                                images: [img.src,...imgs.flatMap(img => img.src)]
                                })" :lazy-src="img.src" :src="img.src" >
                                <template v-slot:placeholder>
                                    <v-row
                                        align="center"
                                        class="fill-height ma-0"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                        color="grey-lighten-5"
                                        indeterminate
                                        ></v-progress-circular>
                                    </v-row>    
                                </template>
                            </v-img>
                        </v-col>
                        
                
                    
                
                    </v-row>
                    <v-row class="direction-rtl padding-px-10">
                        <a class="color-primary">More</a>
                    </v-row>
                </v-container>
                
                
            </v-col>
            <v-col v-if="!mobile || (mobile && currentMenu == 1)"   class="pr-0 pl-0 pt-0 posts min-width-200">
                <div class="d-flex flex-direction-column align-items-flex-end">
                    <CreatePostAuth></CreatePostAuth>
                    <PostCardAuth v-for="item of posts" :key="item.id" :post="item" />
                </div>
            
            </v-col>
        </v-row>
        <v-row :class="{ hide: currentMenu != 2 , 'px-percent-10 px-xs-percent-3': true,  'mt-5': true }">
            <v-col cols="auto" ></v-col>
            <v-col class="pa-0 direction-rtl" >
                <v-btn  @click="openDialog()"  color="primary"  variant="flat" class="mb-5 px-36 min-width-150 bg-primary text-white vertical-align-bottom text-transforme-capitalize mr-0"   >
                        New
                    </v-btn>
            </v-col>
        </v-row>
        <v-row :class="{ hide: currentMenu != 2 , 'px-percent-10 px-xs-percent-5': true,  'mt-5': true }" >
            <v-dialog v-model="dialog" max-width="500px">

                <v-card>
                    <v-card-title>
                        <span class="text-h5">New Project</span>
                    </v-card-title>

                    <v-card-text>
                        <v-container>

                        <v-alert v-if="dialogAlert" :type="dialogAlertType" :title="$t(dialogAlertTitle)" :text="$t(alertSubTitle)"></v-alert>
                        <v-form class="new-project-admin" @submit.prevent v-if="this.editedIndex === -1">
                            <slot  :update="update" :data="createdItem"   name="add-dialogue"></slot>
                            <p class="custum-label">Name</p>
                            <v-text-field class="custum-input" density="compact" variant="tonal" label="">

                            </v-text-field>
                            <p class="custum-lable">Description</p>
                            <v-text-field class="custum-input" density="compact" variant="tonal" label="">

                            </v-text-field>
                            <p class="custum-label">Budget</p>
                            <v-text-field class="custum-input" persistent-hint="How much you plan to spend to realize this project." density="compact" variant="tonal" label="">

                            </v-text-field>
                            <p class="custum-label">Deatline</p>
                            <v-text-field class="custum-input" persistent-hint="When the project is supposed to end."  variant="tonal" density="compact" label="">

                            </v-text-field>
                        </v-form>
                        <v-form @submit.prevent v-else>
                            <slot  :update="update" :data="editedItem"  name="edit-dialogue"></slot>
                        </v-form>
                        
                        

                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue-darken-1" class="px-36 text-transforme-capitalize min-width-150" variant="outlined" @click="close">
                        {{ $t('cancel_btn') }}
                        </v-btn>
                        <v-btn color="blue-darken-1" class="px-36 text-transforme-capitalize min-width-150" variant="flat" @click="save">
                        {{ $t('save_btn') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-data-table-virtual :headers="customHeaders" :show-select="false" :items="projects" :items-length="projects.length">
            <template v-slot:[`header.name`]="{ column }">
                <span class="font-weight-bold name"> {{ column.title }} </span>
            </template>
            <template v-slot:[`header.posts`]="{ column }">
                <span class="font-weight-bold text-align-end justify-content-right d-flex"> {{ column.title }} </span>
            </template>
            <template v-slot:[`header.actions`]="{ column }">
                <span class="font-weight-bold actions text-align-end justify-content-right d-flex"> {{ column.title }} </span>
            </template>
            <template v-slot:[`header.deateline`]="{ column }">
                <span class="font-weight-bold text-align-end justify-content-right d-flex"> {{ column.title }} </span>
            </template>
            <template v-slot:[`item.posts`]="{ item }">
                <span class="font-weight-bold text-align-end justify-content-right d-flex"> {{ item.posts }} </span>
            </template>
            <template v-slot:[`item.deateline`]="{ item }" >
                <span class="font-weight-bold text-align-end justify-content-right d-flex"> {{ item.deateline }} </span>
            </template>
            <template v-slot:[`item.name`]="{ item }" >
                <span class=" name d-flex"> {{ item.name }} </span>
            </template>
            
            <template v-slot:[`item.actions`]="{ item,  }">
                <div class="price justify-content-right text-align-end">XAF<span class="font-weight-bold">{{ item.budget }}</span>
                    <div class="action text-align-end">
                        <v-icon  size="small" class="me-2" @click="viewItem(item)">
                        mdi-eye
                        </v-icon>
                        <v-icon size="small" class="me-2" @click="editItem(item)" >
                        mdi-pencil
                        </v-icon>
                        <v-icon size="small" @click="deleteItem(item)" >
                        mdi-delete
                        </v-icon>
                    </div>
                </div>
                
            </template>
            <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Reset

            </v-btn>
            </template>
        </v-data-table-virtual>
        </v-row>
  
    

    </v-container>
    
</template>
<script>
import Camera from '../assets/Camera.svg';
import Logo from '../assets/logo.png';

import CreatePostAuth from '../widgets/Cards/CreatePostAuth.vue';
import PostCardAuth from '../widgets/Cards/PostCardAuth.vue';

export default {
components: {
    CreatePostAuth,
    PostCardAuth
},
methods: {
    choose(item){
        this.currentMenu = item;
    },
    manage(){
        this.$router.push('/manage')
    },
    openDialog(){
        this.dialog = true;
    },
    save(){

    },
    updateSize(){
        this.$data.mobile = this.$data.q.matches
    },
    close(){
        this.dialog = false;
    },

},
created(){
    var x = window.matchMedia("(max-width: 475px)"); 
    this.$data.q = x;
    this.$data.mobile = x.matches;
},
mounted(){
    this.$data.q.addEventListener("change",this.updateSize)
    this.$data.mobile = this.$data.q.matches;
    let options = {
        root: document,
        threshold: [0.1, 0.2, 0.5],
    };
    function callback(){
        let a = document.querySelector("#mobileNavbar");
        if(this.$data.mobile){
            if(window.scrollY > 75  ){
                a.classList.add('fixed-top')            
            }else{
                a.classList.remove('fixed-top')
            }
        }
        
        console.log(window.scrollY, this.$data.mobile);
    }
    this.$data.observer = new IntersectionObserver(callback.bind(this), options);
    console.log(document.querySelector(".admin"))
    document.addEventListener("scroll",callback.bind(this))
    this.$data.observer.observe( document.querySelector('#router'));

},
unmounted(){
    this.$data.q.removeEventListener("change",this.updateSize)
},
data(){
  return {
    open: false,
    mobile: false,
    camera: Camera,
    logo: Logo,
    dialog: false,
    currentMenu: 1,
    imgs: [
        {
            src: "https://cdn.vuetifyjs.com/images/cards/docks.jpg"
        },
        {
            src: "https://cdn.vuetifyjs.com/images/cards/docks.jpg"
        },
        {
            src: "https://cdn.vuetifyjs.com/images/cards/docks.jpg"
        },
        {
            src: "https://cdn.vuetifyjs.com/images/cards/docks.jpg"
        },
        {
            src: "https://cdn.vuetifyjs.com/images/cards/docks.jpg"
        },
        {
            src: "https://cdn.vuetifyjs.com/images/cards/docks.jpg"
        },
        {
            src: "https://cdn.vuetifyjs.com/images/cards/docks.jpg"
        }
    ],
    customHeaders: [
    {
        title: "Name",
        align: 'start',
        sortable: true,
        width: '40%',
        key: 'name',
    },{
        title: "Post",
        align: 'start',
        sortable: true,
        key: 'posts',
    }
    ,{
        title: "Deateline",
        align: 'start',
        sortable: true,
        key: 'deateline',
    }
    ,{
        title: "Budget",
        align: 'start',
        sortable: true,
        key: 'actions',
    }
    ],
    stats: [
        {
            title: 'salut',
            icon: ''
        },
        {
            title: 'salut',
            icon: ''
        }
    ],
    editedIndex: -1,
    selected: {text: 'Real-Time', icon: 'mdi-progress-helper'},
    posts: [
          { title: "Post 1", id: "1", date: "23 oct 2020", username: "paul", imgs: ["https://cdn.vuetifyjs.com/images/cards/docks.jpg", "https://cdn.vuetifyjs.com/images/cards/docks.jpg", "https://cdn.vuetifyjs.com/images/cards/docks.jpg", "https://cdn.vuetifyjs.com/images/cards/docks.jpg"] },
          { title: "Post 1", id: "2", date: "23 oct 2020", username: "paul", imgs: ["https://cdn.vuetifyjs.com/images/cards/docks.jpg", "https://cdn.vuetifyjs.com/images/cards/docks.jpg", "https://cdn.vuetifyjs.com/images/cards/docks.jpg"] },
          { title: "Post 1", id: "3", date: "23 oct 2020", username: "paul", imgs: ["https://cdn.vuetifyjs.com/images/cards/docks.jpg", "https://cdn.vuetifyjs.com/images/cards/docks.jpg"] },
          { title: "Post 1", id: "4", date: "23 oct 2020", username: "paul", imgs: [ "https://cdn.vuetifyjs.com/images/cards/docks.jpg"] }
    ],
    projects: [
        { name: "Post 1", id: "1", deateline: "23 oct 2020", posts: "14", budget: 20000 },
        { name: "Post 1", id: "2", deateline: "23 oct 2020", posts: "14", budget: 20000 },    
    ],
    items: [
        { text: 'Real-Time', icon: 'mdi-progress-helper' },
        { text: 'Audience', icon: 'mdi-progress-helper' },
        { text: 'Conversions', icon: 'mdi-progress-helper' },
      ],
    menuItems: [
      { text: 'Account settings', icon: 'mdi-account-cog-outline' },
      { text: 'Logout', icon: 'mdi-logout'}
    ]
  }
}
}
</script>
<style>
.pl-px-5{
    padding-left: 5px;
} 
.pr-px-5{
    padding-right: 5px;
}

.pl-px-10{
    padding-left: 10px !important;
}
.navbar-second,.navbar-second-content{
  
    justify-content: center;
    align-content: center;
    display: flex;
    align-items: center;
  
}
.height-56{
    height: 56px;
}
.stats-box .v-card-item{
    padding-left: 5px !important;
    padding-right: 5px !important;
}
.admin .navbar.nav-custum-bar{
    height: 56px;
    max-height: 56px;
}
.pl-px-2{
    padding-left: 2px;
} 
.pr-px-2{
    padding-right: 2px;
}
</style>
<style scoped>
.mt-px--16{
    margin-top: -16px;
}
.font-size-20{
    font-size: 20px;
}

.new-project-admin .v-field__input, .new-project-admin .v-field {
    background-color: #EAEAEA;
}
.font-weight-bolder{
    font-weight: bolder;
}
.overlow-scroll{
    overflow: scroll;
}
.bg-img{
    background-color: #EAEAEA;  
}
.padding-px-5{
    padding-left: 5px !important;
    padding-right: 5px !important;
}
.gap-2{
    gap: 2px;
}
.pl-px-9{
    padding-left: 9px;
}
.min-width-200{
    min-width: 200px;
}

.cursor-pointer{
    cursor: pointer;
}
.min-width-150{
    min-width: 150px;
}
.gap-px-1{
    gap: 1px;
}
.pa-px-1{
    padding: 1px !important;
}
.flex-direction-column{
    flex-direction: column;
    
} 
.align-items-flex-end{
    align-items: flex-end;
}

.padding-px-10{
    padding: 10px;
}
.chevrondown-content{
  background-color: #EAEAEA;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  border: solid 2px white;
  padding: 5px;
  top: 75%;
  left: 75%;

}
.text-align-end{
    text-align: end;
}
.boder-bottom-gray{
    border-bottom: solid 2px black;
}
.action{
    display: none;
}
.price{
    position: relative;
}
.admin .th{
    padding-right: 0px !important;
} 
.divider{
    width: 100%;
    height: 1px;
    margin: 0px;
    padding: 0px;
    background-color: #DDDDDD;
}
.price:hover .action{
    display: inline-block;
    position: absolute;
    top: 0%;
    right: 0%;
    background-color: rgba(0, 0, 0, 0.075);
}
.expense-title{
    font-size: 15px;
}
.media{
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
}
.fontWeigthBolder{
    font-weight: bolder;
}
.chevrondown{
  
width: 100%;
height: 100%;
}
.color-primary{
    color: #158CCE;
}
.border-gray{
 border-color: #DDDDDD;
}
.medias-box{
 min-height: 30vh;
 border: solid 1px #DDDDDD;
 border-radius: 5px;
 max-height: 50vh;
 max-width: 35vw;
}
.px-percent-10{
    padding-left: 10%;
    padding-right: 10%;
}
.fixed-top{
    position: fixed;
    display: flex;
    width: 106vw;
    top: 0px;
    left: 0px;
    z-index: 5;
    padding-right: 0px !important;

    background-color: white;
    height: 56px;
}
#mobileNavbar.fixed-top > p{
 padding-top: 15px;
}
.gap-2{
    gap: 2px;
}
.w-px-200{
    width: 150px;
    height: 150px;
}
.text-transform-capitalize{
    text-transform: capitalize;
}
.direction-rtl{
    direction: rtl;
}
.font-size-10{
    font-size: 10px;
}
.stat-text{
    font-weight: bolder;
    font-size: 18px;
}
.justify-content-center{
    justify-content: center;
}
.divider{
    height: 1px;
    border-bottom: solid 1px #DDDDDD;
   
    width: 100%;
}
.hide{
    display: none;
}
.text-align-left{
    text-align: left;
}
.justify-content-right{
    justify-content: right;
}
.height-30{
    height: 30px;
}
@media (max-width: 1442px) {
    .stat-column > .v-card{
        max-width: 100% !important;
    }
}

@media screen and (max-width: 475px) {
    .xs-text-align-end{
    text-align: end;
}
    .medias-box{
        min-height: 70vh;
        max-height: 90vh;
    }
    .xs-text-align-center{
        text-align: center;
    }
    .bg-img{
        display: block;
        margin: auto;
    }
    .pl-xs-px-0{
        padding-left: 0px !important;
    }
    .admin{
        padding: 0px;
        padding-top: 16px !important;
    }
    .admin > .v-row, .admin .v-row.px-percent-10{
        padding-left: 15px;
        padding-right: 15px;
    }
    
    
    .medias-box{
       max-width: 100%;
    }
    .stat-column{
        width: 100%;
    }
    .xs-flex-direction-column{
        flex-direction: column;
    }
    .mt-xs-0{
        margin-top: 2px !important;
    }
}
@media (max-width: 600px){
    .mt-sm-2{
        margin-top: 2px !important;
    }
    .pl-mobile-0{
        padding-left: 0px !important;
    }
    .px-percent-10{
        padding-left: 5%;
        padding-right: 5%;
    }
}
@media screen and (max-width: 320px) {
    .px-xs-percent-3{
        padding-left: 3px;
        padding-right: 3px;
    }
    .mt-xs-0{
        margin-top: 2px !important;
    }
    .pl-xs-16{
        padding-left: 16px !important;
    }
    .medias-box{
        min-height: 80vh;
    }
}

</style>