export const API = {

	'RESSOURCES'             : (ressource) => `/ressources/${ressource}/`,
	'SEARCH_RESSOURCES'      : (ressource) => `/ressources/${ressource}/search/`,
	'EXTRACT_RESSOURCES'     : '/ressources/extract/',
	'LINK_RESSOURCES'        : '/link/',
	'CORE_ENTITITY'			 : '/core/entity/',
	'CORE_ENTITITIES'	     : '/core/entities/',
	'CORE_ENTITITIES_STATS'	 : (aggregation)=> `/core/entities/${aggregation}/`,	
	'LINKS_RESSOURCES'       : '/links/',
	'PERMUTATION_RESSOURCES' : (ressource) => `/ressources/${ressource}/permutation/`,
	'VALIDATE_RESSOURCES'    : (ressource) => `/ressources/${ressource}/validate/`
};

export const FRONT = {
	'BASE': '/',
	'AUTHENTIFICATION' : {
		'SINGN_IN'        : '/authentifications/sign-in',
		'SINGN_UP'        : '/authentifications/sign-up',
		'PASSWORD_FORGOT' : '/authentifications/password-forgot'
	},
	'OFFLINE'   : '/offline',
	'SIGNOUT'   : '/authentifications/signout',
	'DASHBOARD' : {
		'BASE': '/dashboard/',
		'MESSAGES': '/dashboard/messages/',
		'USERS': '/dashboard/users/',
		'SERVICES': '/dashboard/services/',
		"WHITENUMBERS": "/dashboard/whitesnumbers/"
	}
    
};

export default { API, FRONT };
