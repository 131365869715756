import {  isEmpty } from "lodash";

export function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function isAuthentificate ()
{
    const token = localStorage.getItem('token');
    if(isEmpty(token)){
        return false;
    }
    const data = parseJwt(token)

    if( Date.now()/1000 < data.exp){
        return true;
    }
    localStorage.removeItem('token');

    return false
}
