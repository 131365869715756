import router from './router';
import store from './store';
import chartist from './chartist';
import veeValidate from './vee-validate';
import vuetify from './vuetify';
import i18n from './i18n';
import register from './base';

import VueViewer from 'v-viewer'

export function registerPlugins(app){
    
    app.use(vuetify);
    app.use(i18n);
    app.use(store);
    app.use(router);
    app.use(chartist);
    app.use(VueViewer)
    veeValidate(app);
    register(app);
   
}
