<template>
  <div class="navbar nav-custum-bar bg-base-100 pa-0 ">
    <div class="flex-1 navigation-icons pl-5">
      <v-icon @click="unSearch()" v-if="mobile && searchMode">mdi-keyboard-backspace</v-icon>
      <v-icon @click="showDrawer()" v-if="mobile && !searchMode">mdi-menu</v-icon>
      <RouterLink v-if="!mobile && !tablet && !laptop" to="/" class="text-black normal-case text-xl"
          >PTA Expense Tracking</RouterLink
        >
      <v-avatar v-if="tablet || laptop" size="50">
        <v-img :src="logo"></v-img>
      </v-avatar>
    </div>
    <CustomSearch
   
    ></CustomSearch>
    <div style="width: 10vw;"></div>
 

    <div class="flex-none">
      <div class="dropdown dropdown-end">
        <label tabindex="0" class="btn btn-ghost btn-circle avatar mr-5">
          <div class="w-10 rounded-full">
            <img src="https://api.lorem.space/image/face?hash=33791" />
            
          </div>
          <div class="chevrondown-content rounded-full">
            <img class="chevrondown" :src="chevrondown" />
          </div>
        </label>
        <div
          tabindex="0"
          class="
            mt-0
            p-3
            mr-2
            shadow
            menu menu-compact
            dropdown-content
            bg-base-100
            rounded-box
            w-52
          "
        >
           <h2 class="pa-5 font-weigth-bolder">Fezeu Eugene</h2>
          <v-card class="mb-2" elevation="2">
            <v-list >
              <v-list-item class="font-weigth-bolder">

                <template v-slot:prepend>
                  <v-icon :icon="selected.icon"></v-icon>
                </template>

                <v-list-item-title class="font-weigth-bolder" v-text="selected.text"></v-list-item-title>
                
              </v-list-item>
              <v-divider inset></v-divider>
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
              >
                <template v-slot:prepend>
                  <v-icon :icon="item.icon"></v-icon>
                </template>

                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
          <v-list density="compact" disabled>
  
            <v-list-item
            
              v-for="(item, i) in menuItems"
              :key="i"
            >
              <template v-slot:prepend>
                <v-icon :icon="item.icon"></v-icon>
              </template>

              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item>
          </v-list> 
        </div>
      </div>
    </div>
  </div>
  
</template>
<script>
import ChevronDown from '@/assets/ChevronDown.svg';
import Ikwen from '../../assets/Ikwen.png';
export default {
  
  computed:{
    tablet(){
      return this.$vuetify.display.sm;
    },
    laptop(){
      return this.$vuetify.display.md;
    },
    mobile(){
      return this.$vuetify.display.xs;
    },
    searchMode(){
      return this.mobile && this.searchToggle;
    }
  },
components: {
},
methods: {
  showDrawer(){
      window.dispatchEvent(new CustomEvent('showdrawer'))
     
    },
    showAchievementDrawer(){
      window.dispatchEvent(new CustomEvent('showachievementdrawer'))
    },
  search(){
      this.searchToggle = true;      

    },
    unSearch(){
      this.searchToggle = false;
    }
},
data(){
  return {
    searchToggle: false,
    open: false,
    logo: Ikwen,
    chevrondown: ChevronDown,
    selected: {text: 'Real-Time', icon: 'mdi-progress-helper'},
    items: [
        { text: 'Real-Time', icon: 'mdi-progress-helper' },
        { text: 'Audience', icon: 'mdi-progress-helper' },
        { text: 'Conversions', icon: 'mdi-progress-helper' },
      ],
    menuItems: [
      { text: 'Account settings', icon: 'mdi-account-cog-outline' },
      { text: 'Logout', icon: 'mdi-logout'}
    ]
  }
}
}
</script>
<style scoped>
.nav-custum-bar{
background-color: #D9D9D9;
color: black;
min-height: 56px!important;
}

.nav-custum-bar .menu{
z-index: 300;
min-width: 300px;
background-color: white;
}
.pt-18{
padding-top: 18px;
}
.font-weigth-bolder{
  font-weight: bolder;
}
.chevrondown-content{
  background-color: #EAEAEA;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: 70%;
  left: 65%;

}
.chevrondown{
  
width: 100%;
height: 100%;
}
.nav-link-btn{
background-color: #355698;
color: white;
border-radius: 5px;
min-width: 90px;
text-align: center;
display: inline-block;

}
@media (max-width: 425px){

  .navbar{
    min-height: 56px !important;
  }
  
}

</style>