<template>
    <v-row class="ma-0">
      <v-col class="hide-mobile">
        
        <div class="circular--landscape ma-auto"> <img
          
          src="https://picsum.photos/800/500"
          width="250"
          height="250"
          class="circular--square ma-auto "
        /> </div>
      </v-col>
      <v-col >
        <v-card  class="mt-percent-25 mobile-card" :elevation="0">
          <v-card-title class="pl-0">Login</v-card-title>
          <form>
            <p class="custum-label">Eska Phone number</p>
            <v-text-field label="" hide-details density="compact" class="custum-input" variant="outlined"></v-text-field>
            <p class="custum-label mt-3">Password</p>
            <v-text-field hide-details density="compact" class="custum-input" label="" type="password" variant="outlined"></v-text-field> 
            <p class="text-align-rigth">            <RouterLink to="/password-forgotten" class="text-caption text-decoration-none text-blue"
            >Forgotten password ?</RouterLink></p>

            <v-btn :loading="loading" @click="submit" color="primary" variant="flat"   class="mb-5 mt-10  rounded-5 vertical-align-bottom text-transforme-capitalize w-100">Submit</v-btn>
          </form>
        </v-card>

      </v-col>
    </v-row>
  
</template>

<script >
export default {
  name: 'Login',
  methods: {
    submit(){
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.$router.push('/dashboard')
      }, 2000);
    }
  },
  data(){
    return {
      loading: false
    }
  }
}
</script>
<style scoped>
.rounded-50{
  border-radius: 50%;
}
.circular--landscape { display: inline-block; position: relative; width: 200px; height: 200px; overflow: hidden; border-radius: 50%; 
    top: 25%;
    left: 25%;
 } 
 .circular--landscape img { width: auto; height: 100%; margin-left: -50px; }

.mt-percent-25{
  margin-top: 25%;
}
.text-align-rigth{
  text-align: right;
}
.mobile-card{
    max-width: 30vw;
}
@media (max-width: 600px){
  .hide-mobile{
    display: none;
  }
}
@media (max-width: 425px){
  
  .ma-0{
    margin: 0px;
  }
  .mobile-card{
    max-width: 100vw;
    padding: 5px;
  }
}
</style>