<template>
    <div class="bg-app-blue post-card auth sized    w-100 ">
      <v-container class="pa-px-12" fluid>
        <v-row>
        <v-col v-if="this.$vuetify.display.mdAndUp" class="xs-pl-0 pl-0 pr-0" cols="auto"> 
          <v-avatar size="40" class="ma-0">
            <v-img
              src="https://cdn.vuetifyjs.com/images/john.jpg"
              alt="John"
              class="pa-0"
            ></v-img>
          </v-avatar>
        </v-col>        
        <v-col class="pr-0 pt-0 pl-0 xs-pl-0">
          <v-card
            class="mx-auto bg-transparent pr-0"
            variant="flat"
            
            title="a"
          
          >
            <template v-if="!this.$vuetify.display.mdAndUp" v-slot:prepend>
              <v-avatar   size="40" class="ma-0">
                <v-img
                  src="https://cdn.vuetifyjs.com/images/john.jpg"
                  alt="John"
                ></v-img>
              </v-avatar>
            </template>
            <v-card-text class="font-weight-bolder pb-0">XFA 2 000 000</v-card-text>
            <v-card-subtitle class="mt-3">Paiement table, tableau et chaises</v-card-subtitle>
            <template v-slot:title="">
              <p class="font-size-14 pt-0 pb-0"> <span class="font-weight-bolder">{{ post.title }}</span>  <span class="post-card-username">@{{ post.username }}</span></p>
            </template>
            <template v-slot:append>
              <span class="post-date">{{ post.date }}</span>
              
            </template>
            
            <div class="img-box mt-3">
              
              <template v-if="post.imgs.length > 0">
                <v-row class="card-gallerie mt-2 gap-px-1">
                  
                    <v-col @click="this.$viewerApi({
                        options: { navbar: post.imgs.length > 1,
                        fullscreen: true },
          images: post.imgs
        })" class="pl-0 pr-0 pt-0 pb-0 min-width-percent-49" v-for="img, index of post.imgs"
                      :key="index">
                      <v-img
                      
                      class="img-preview sm-border-normal border-rounded d-flex" 
                          height="200"
                          
                          :src="img"
                          cover
                      >
                        
                      </v-img>
                    </v-col>
                </v-row>
              </template>
              
            </div>
            <v-card-actions class="pl-px-16  mt-3 pt-0 pr-px-16">
                  
                  <div class="text-align-center">
                    <v-btn class="pr-0 pl-0" @click="comment" size="small" color="surface-variant" variant="text" icon="mdi-comment-processing-outline"></v-btn>
                    <span class="font-size-12">824</span>
                  </div>
                      
                  <v-spacer></v-spacer>
                  <div class="text-align-center">  
                    <v-btn size="small" color="#268CEA99" variant="text" icon="mdi-thumb-up-outline"></v-btn>
                    <span class="font-size-12">124</span>
                  </div>  
                  <v-spacer></v-spacer>
                  <div class="text-align-center">
                    <v-btn size="small" color="red" variant="text" icon="mdi-thumb-down-outline"></v-btn>
                    <span class="font-size-12">05</span>
                  </div>    
    
                      
                  <v-spacer></v-spacer>
                  <div class="text-align-center">
                    <v-btn class="pr-2 lg-max-width-30" size="small" color="surface-variant" variant="text" icon="mdi-cash"></v-btn>
                    <span class="font-size-12 text-overflow-ellipse">XAF 1000000000000</span>
                  </div>
    
                </v-card-actions>   
          </v-card>
        </v-col>
      </v-row>
      
      </v-container>
     
     
      
    </div>
    <v-divider></v-divider>
  </template>
  
  <script>
  export default {
    emits: ['comment'],
    props: { post: Object  },
    methods: {
      comment(){
        
        window.dispatchEvent(new CustomEvent('comment',{
          detail: {
            title: this.title, id: this.id, date: this.date
          }
        }))


        
      }
    }
  };
  </script>
<style >
.min-width-percent-49{
  min-width: 49%;
}
.cursor-pointer{
  cursor: pointer;
}
@media (min-width: 700px) {
  .sized{
    max-width: 99%;
  }
  
}

.auth .v-card > .v-card-item{
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 11px;
}
.auth .v-card > .v-card-text, .auth .v-card > .v-card-subtitle {
  padding-right: 0px;
  padding-left: 11px;
}
.pa-px-12{
  padding: 12px !important;
}
.font-size-14{
  font-size: 14px;
}
.text-align-center{
  text-align: center;
}
.font-size-12{
  font-size: 12px !important;
}
.post-card .v-img.card-image img{
  padding: 16px;
  border-radius: 20px !important;
}
.manage .img-box{
  display: flex;
}
.gap-px-1{
  gap: 1px;
}

.admin .img-box{
    padding-left: 24px ;
    padding-right: 12px;
  }
@media (max-width: 500px){
  .post-card .v-img.card-image img{
  padding: 0px;
  border-radius: 0px!important;
  } 
}

@media  (max-width: 425px ) {
  .xs-pl-0{
    padding-left: 0px !important;
  }
  
}

@media (max-width: 475px) {
  .admin .img-box{
    padding-left: 12px !important;
    padding-right: 12px;
  }
  .auth .v-card > .v-card-item{
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 4px;
}
.auth .v-card > .v-card-text, .auth .v-card > .v-card-subtitle {
  padding-right: 0px;
  padding-left: 0px;
}
}

.pl-px-16{
 padding-left: 16px !important;
}
.pr-px-16{
 padding-right: 16px !important;
}
.font-size-14{
  font-size: 14px;
}
.font-weight-bolder{
  font-weight: bolder;
}
.post-card-username{
  
  font-weight: 400;
}
.post-date{
  font-size: 11px;
}
.post-card .v-card-item{
  padding-top: 0px;
  padding-bottom: 0px;
}
.img-preview{
  min-width: 100px;
}
</style>