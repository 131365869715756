<template>
    <v-row class="ma-0">
      <v-col class="hide-mobile">
        
        <div class="circular--landscape ma-auto"> <img
          
          src="https://picsum.photos/800/500"
          width="250"
          height="250"
          class="circular--square ma-auto "
        /> </div>
      </v-col>
      <v-col >
        <v-card  class="mt-percent-25 mobile-card" :elevation="0">
          <p class="pl-0 custum-label">Password Forgotten, Eska Phone number. </p>
           <form>
            <v-text-field label="" density="compact" variant="outlined"></v-text-field>
            <p class="text-align-rigth">            <RouterLink to="/login" class="text-caption text-decoration-none text-blue"
            >Login ?</RouterLink></p>

            <v-btn color="primary" variant="flat"  class="mb-5 mt-5  rounded-5 vertical-align-bottom text-transforme-capitalize w-100">Submit</v-btn>
          </form>
        </v-card>

      </v-col>
    </v-row>
  
  </template>

<script >
export default {
  name: 'Login',

}
</script>
<style>
.rounded-50{
  border-radius: 50%;
}
.circular--landscape { display: inline-block; position: relative; width: 200px; height: 200px; overflow: hidden; border-radius: 50%; 
    top: 25%;
    left: 25%;
 } 
 .circular--landscape img { width: auto; height: 100%; margin-left: -50px; }
 .text-transforme-capitalize{
    text-transform: capitalize;
}
.mt-percent-25{
  margin-top: 25%;
}
.text-align-rigth{
  text-align: right;
}
.mobile-card{
    max-width: 30vw;
}
@media (max-width: 425px){
  .hide-mobile{
    display: none;
  }
  .ma-0{
    margin: 0px;
  }
  .mobile-card{
    max-width: 100vw;
    padding: 5px;
  }
}
</style>