<template>
    
    <div class="navbar home-bar nav-custum-bar bg-white pt-0 pl-0 pr-0 pb-0 ">
      

      <div class="flex-1 navigation-icons xs-max-width-70 ">
        <v-icon @click="unSearch()" v-if="mobile && searchMode">mdi-keyboard-backspace</v-icon>
        <v-icon @click="showDrawer()" v-if="mobile && !searchMode">mdi-menu</v-icon>

        <RouterLink v-if="!mobile && !tablet && !laptop" to="/" class="text-black normal-case text-xl"
          >PTA Expense Tracking</RouterLink
        >
        <v-avatar v-if="tablet || laptop" size="50">
          <v-img :src="logo"></v-img>
        </v-avatar>

        
      </div>
      
      <div class="flex search-box-home  search-width-percent">
        <CustomSearch
          v-if="!mobile || (mobile && searchMode)"
        ></CustomSearch>
      </div>
     
      <v-icon @click="showAchievementDrawer()" v-if="mobile && !searchMode">mdi-trophy</v-icon>
      
      <v-icon @click="search()" class="ml-2 mr-2 position-relative-3" v-if="mobile && !searchMode">mdi-magnify</v-icon>
      <div v-if="!mobile" class="flex-none">
        <ul class="menu menu-horizontal p-0">
          <li v-if="$route.name != 'AddSchool'"><RouterLink class="nav-link-btn min-width-170 pt-2 pb-2 px-36" to="/add-school" > <v-icon color="white">mdi-plus</v-icon> Add School</RouterLink></li>
          <li class="ml-2 mr-2" ><RouterLink class="nav-link-btn min-width-170  pt-2 pb-2 px-36" to="/login"> <v-icon color="white">mdi-login</v-icon> Login</RouterLink></li>
          <li><RouterLink v-if="isLoginedIn" to="/notifications"><v-icon>mdi-bell-outline</v-icon></RouterLink></li>
      
        </ul>
      </div>

      <div class="flex-none" v-if="isLoginedIn">
        <div class="dropdown dropdown-end">
          <label tabindex="0" class="btn btn-ghost btn-circle avatar">
            <div class="w-10 rounded-full">
              <img src="https://api.lorem.space/image/face?hash=33791" />
            </div>
          </label>
          <ul
            tabindex="0"
            class="
              mt-3
              p-2
              shadow
              menu menu-compact
              dropdown-content
              bg-base-100
              rounded-box
              w-52
            "
          >
            <li>
              <a class="justify-between">
                Profile

              </a>
            </li>
            <li><a>Settings</a></li>
            <li><a>Logout</a></li>
          </ul>
        </div>
      </div>
    </div>
    
  </template>
<script>
import Ikwen from '../../assets/Ikwen.png';
export default {
  components: {
  },
  mounted(){
    this.focus();
  },
  computed:{
    tablet(){
      return this.$vuetify.display.sm;
    },
    laptop(){
      return this.$vuetify.display.md;
    },
    mobile(){
      return this.$vuetify.display.xs;
    },
    searchMode(){
      return this.mobile && this.searchToggle;
    }
  },
  methods: {
    focus(){
      try {
        document.querySelector(".input-box input").focus();  
      } catch (error) {
        console.log(error)
      }
      
    },
    showDrawer(){
      window.dispatchEvent(new CustomEvent('showdrawer'))
     
    },
    showAchievementDrawer(){
      window.dispatchEvent(new CustomEvent('showachievementdrawer'))
    },
    search(){
      this.searchToggle = true;      
      this.focus();

    },
    unSearch(){
      this.searchToggle = false;
    }
  },
  setup(){
    
  },
  data(){
   
    return {
      drawer: false,
      searchToggle: false,
      open: false,
      logo: Ikwen
    }
  }
}
</script>
<style scoped>
.navbar{
  min-height: 56px;
}
.nav-custum-bar{
  background-color: #D9D9D9;
  color: black;
}


.nav-custum-bar .menu{
  z-index: 300;
}
.pt-18{
  padding-top: 18px;
}
.search-box-home{
  flex-grow: 1;
}
.position-relative-3{
  position: relative;
  left: 3px;
}
.nav-link-btn{
  background-color: rgb(24, 103, 192);
  color: white;
  border-radius: 5px;
  min-width: 90px;
  text-align: center;
  display: inline-block;
  
}

.min-width-170{
  min-width: 170px;
}
.search-width-percent{
  width: 19%;
}
@media (max-width: 425px){
  .search-width-percent{
    width: 80%;
  }
  .xs-max-width-70{
    max-width: 70px;
  }
  .navbar{
    min-height: 56px !important;
  }
  
}

@media (max-width: 400px){
  .navigation-icons{
    padding-left: 2px;
  }
}

@media (min-width: 400) and (max-width: 600){
  .navigation-icons{
 
  }
}
@media (min-width: 500px) and (max-width: 599px)  {
  .navbar.home-bar{
    max-width: 500px !important;
    margin: auto;
  } 
  .navigation-icons{
    max-width: 100px !important;
  }
  .search-width-percent{
    max-width: 430px;
  }
  .navigation-icons{
   
  }
}
@media (max-width: 499px)  {
  .navigation-icons{
    max-width: 70px !important;
  }
}
@media (min-width: 1200px) {
  .search-box-home{
          padding-left: 63px !important;
        }
}
</style>