//import Vue from 'vue'
import {
  defineRule,
  Field, Form, ErrorMessage
} from 'vee-validate'
import {
  email,
  max,
  min,
  numeric,
  required,
} from '@vee-validate/rules'

defineRule('email', email)
defineRule('max', max)
defineRule('min', min)
defineRule('numeric', numeric)
defineRule('required', required)

function register(app){
  app.component('Field', Field);
  app.component('Form', Form);
  app.component('ErrorMessage', ErrorMessage);

}
export default register;